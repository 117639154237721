<template>
	<div class="decoration" v-if="form">
		<section class="form no-border mt-2">
			<h3>รูปหลักหน้าร้าน <span>ขนาดไม่เกิน 10MB</span></h3>
			<div class="row">
				<div class="col col-6 col-md-4">
					<div class="input-picture">
						<template v-if="form['picture1'] !== ''">
							<button @click="setPicture('', (1))">
								<b-icon icon="x"></b-icon>
							</button>
							<div class="wrapper">
								<img :src="form['picture1']" />
							</div>
						</template>
					</div>
					<take-picture
						fileErrorTitle="ประเภทไฟล์ไม่ถูกต้อง!"
						fileTypeErrorMessage="โปรดเลือกไฟล์รูปภาพที่เป็น JPG หรือ PNG เท่านั้น"
						fileSizeErrorMessage="โปรดใช้รูปขนาดไม่เกิน 10MB"
						@load="setPicture($event, 1)"
						:aspectRatio="4 / 3"
					>เพิ่มรูปหลัก (แนวนอน)</take-picture>
				</div>
				<div class="col col-6 col-md-4">
					<div class="input-picture">
						<template v-if="form['pictureMobile'] !== ''">
							<button @click="setPictureMobile('')">
								<b-icon icon="x"></b-icon>
							</button>
							<div class="wrapper">
								<img :src="form['pictureMobile']" />
							</div>
						</template>
					</div>
					<take-picture
						fileErrorTitle="ประเภทไฟล์ไม่ถูกต้อง!"
						fileTypeErrorMessage="โปรดเลือกไฟล์รูปภาพที่เป็น JPG หรือ PNG เท่านั้น"
						fileSizeErrorMessage="โปรดใช้รูปขนาดไม่เกิน 10MB"
						@load="setPictureMobile($event)"
						:aspectRatio="27 / 50"
					>เพิ่มรูปหลัก (สำหรับจอมือถือ)</take-picture>
				</div>
			</div>
		</section>

		<section class="form mt-5">
			<h3>รูปร้านค้า <span>ขนาดไม่เกิน 10MB</span></h3>
			<div class="row">
				<div class="col col-6 col-md-4" v-for="index in totalPicture" :key="index">
					<div class="input-picture">
						<template v-if="form['picture' + (index + 1)] !== ''">
							<button @click="setPicture('', (index + 1))">
								<b-icon icon="x"></b-icon>
							</button>
							<div class="wrapper">
								<img :src="form['picture' + (index + 1)]" />
							</div>
						</template>
					</div>
					<div class="alt-text">
						<input type="text" v-model="form['imageAlt' + (index + 1)]" placeholder="ตั้งชื่อรูป ..." />
					</div>
					<take-picture
						fileErrorTitle="ประเภทไฟล์ไม่ถูกต้อง!"
						fileTypeErrorMessage="โปรดเลือกไฟล์รูปภาพที่เป็น JPG หรือ PNG เท่านั้น"
						fileSizeErrorMessage="โปรดใช้รูปขนาดไม่เกิน 10MB"
						@load="setPicture($event, (index + 1))"
						:aspectRatio="4 / 3"
					>เพิ่มรูป</take-picture>
				</div>
			</div>
		</section>

		<section class="form mt-5">
			<h3>รูปแบนเนอร์ร้านค้า <span>ขนาดไม่เกิน 10MB</span></h3>
			<div class="row">
				<div class="col col-12" v-for="index in totalBanner" :key="index">
					<div class="input-picture input-banner">
						<template v-if="form['banner' + index] !== ''">
							<button @click="setBanner('', (index))">
								<b-icon icon="x"></b-icon>
							</button>
							<div class="wrapper">
								<img :src="form['banner' + index]" />
							</div>
						</template>
					</div>
					<take-picture
						fileErrorTitle="ประเภทไฟล์ไม่ถูกต้อง!"
						fileTypeErrorMessage="โปรดเลือกไฟล์รูปภาพที่เป็น JPG หรือ PNG เท่านั้น"
						fileSizeErrorMessage="โปรดใช้รูปขนาดไม่เกิน 10MB"
						@load="setBanner($event, index)"
						:aspectRatio="4 / 1"
					>เพิ่มรูป</take-picture>
				</div>
			</div>
		</section>


		<div class="text-center mt-4">
			<button class="btn btn-main btn-submit" @click="submitUpdate()">บันทึกการแก้ไข</button>
		</div>
	</div>
</template>

<script>
import Helper from '@/services/helper';
import SellerService from '@/services/sellerService';
import RouterPath from '@/router/path';

export default {
	data() {
		return {
			totalPicture: 5,
			totalBanner: 1,
			load: null,
			form: null
		}
	},
	async mounted() {
		let loader = this.$modalLoader.render();
		await this.loadDecoration();
		this.initPage();
		loader.hide();
	},
	methods: {
		initPage() {
			this.form = this.initForm();
		},
		initForm() {
			const form = {
				picture1: this.load ? this.load.picture1 : '',
				pictureMobile: this.load ? this.load.pictureMobile : '',
				picture2: this.load ? this.load.picture2 : '',
				picture3: this.load ? this.load.picture3 : '',
				picture4: this.load ? this.load.picture4 : '',
				picture5: this.load ? this.load.picture5 : '',
				picture6: this.load ? this.load.picture6 : '',
				banner1: this.load ? this.load.banner1 : '',
				imageAlt2: this.load ? this.load.imageAlt2 : '',
				imageAlt3: this.load ? this.load.imageAlt3 : '',
				imageAlt4: this.load ? this.load.imageAlt4 : '',
				imageAlt5: this.load ? this.load.imageAlt5 : '',
				imageAlt6: this.load ? this.load.imageAlt6 : ''
			};

			return form;
		},
		setPicture(value, index) {
            this.form['picture' + index] = value;
        },
		setPictureMobile(value) {
            this.form['pictureMobile'] = value;
        },
		setBanner(value, index) {
            this.form['banner' + index] = value;
        },
		async loadDecoration() {
			let loader = this.$modalLoader.render();
			const result = await SellerService.getDecoration();

			if (result.status == 401) {
				this.$router.push(RouterPath.SELLER_LOGIN);
			} else if (result.data) {
				this.load = result.data;
			}

			loader.hide();
		},
		async submitUpdate() {
			if (this.validate()) {
				let loader = this.$modalLoader.render();
				const result = await SellerService.saveStoreDecoration(this.form);

				if (result.data?.success) {
					location.reload();
				} else {
					loader.hide();
					this.$swal(Helper.warningAlert('ยังไม่สามารถบันทึกข้อมูลได้', result.data?.message));
				}
			}
        },
		validate() {
            let isValid = true;
            let isBlank = false;

            // Check Blank
			if (!this.form.picture1) {
				isValid = false;
				isBlank = true;
			}

            // Popup
            if (isBlank) {
                this.$swal(Helper.warningAlert('ยังไม่ได้ลงรูปภาพหลัก', 'โปรดใส่รูปภาพหลักของร้านค้าท่าน'));
            }

            return isValid;
		}
	}
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.form {
	border-top: 1px solid #ccc;
	padding-top: 40px;

	&.no-border {
		border-top: none;
		padding-top: 0;
	}

	.row {
		margin-left: -7px;
		margin-right: -7px;

		.col {
			padding-left: 7px;
			padding-right: 7px;
			margin-bottom: 15px;
		}
	}

	.input-banner {
		padding-top: 25%;
	}

	h3 {
		font-size: 15px;
		font-weight: bold;
		margin-bottom: 20px;

		span {
			margin-left: 10px;
		}
	}

	.input-banner {
		padding-top: 25%;
	}

	.alt-text {
		padding: 5px 0;

		input {
			display: block;
			font-size: 13px;
			width: 100%;
			padding: 8px 16px;
			border: 1px solid #aaa;
		}
	}
}

.btn-submit {
	width: 200px;
	height: 50px;
	font-size: 18px;
	@include boxShadow(2px 2px 5px rgb(0 0 0 / 15%));
}
</style>